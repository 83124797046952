import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import PageGridItemProps from "./PageGridItemProps";

const PageGridItem = loadable(
    (): Promise<DefaultComponent<PageGridItemProps>> =>
        import(/* webpackChunkName: "PageGridItem" */ "./PageGridItem"),
);
const PageGridItemLoader = (props: PageGridItemProps): ReactElement => (
    <PageGridItem {...props} />
);
export default PageGridItemLoader;
