import { styled } from "styled-components";

import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding-bottom: 4.375rem;
    padding-top: 2.1875rem;
    position: relative;

    ${MQ.FROM_M} {
        padding-bottom: 6.125rem;
        padding-top: 3.5rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 8.75rem;
        padding-top: 4.375rem;
    }
`;

export const Pages = styled.div.attrs<{
    $count: number;
}>(() => ({}))`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(1, [col-start] 1fr);
    row-gap: 2.625rem;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(2, [col-start] 1fr);
        row-gap: 3.5rem;
    }

    ${MQ.FROM_L} {
        grid-template-columns: repeat(3, [col-start] 1fr);
        row-gap: 3.5rem;
    }

    ${MQ.FROM_XL} {
        row-gap: 4.375rem;
    }
`;
