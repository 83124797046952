import React, { ReactElement } from "react";

import PageGridItem from "components/PageGridItem";
import Section from "components/Section";
import { addEditAttributes } from "utils/episerver";

import { Container, Pages } from "./PageGrid.styled";
import PageGridProps from "./PageGridProps";

const PageGrid = ({
    identifier,
    theme,
    pages,
    anchorName,
    className,
    l18n,
}: PageGridProps): ReactElement => {
    return (
        <Section id={anchorName} theme={theme} alignItems="center">
            <Container
                key={identifier || `PageGridContent`}
                {...addEditAttributes("PageGrid")}
            >
                <Pages $count={pages ? pages.length : 0} className={className}>
                    {pages?.map((page, index) => (
                        <PageGridItem
                            key={`page${index}`}
                            theme={theme}
                            l18n={l18n}
                            {...page}
                        />
                    ))}
                </Pages>
            </Container>
        </Section>
    );
};

export default React.memo(PageGrid);
